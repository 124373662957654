import styled from 'styled-components'

const NavMenuAndPageContainer = styled.div`
  height: 100%;
`

const NavPusher = styled.div`
  display: block;
  height: 60px;
  width: 100%;
`

const ContentContainer = styled.div`
  min-height: ${props =>
    props.theme.justify === 'min-height' ? props.theme.height : 'unset'};
  height: ${props =>
    props.theme.justify === 'height' ? props.theme.height : 'unset'};
  display: block;
  overflow-y: auto;
`
ContentContainer.defaultProps = {
  theme: {
    height: '100vh',
    justify: 'min-height',
  },
}

export { NavPusher, NavMenuAndPageContainer, ContentContainer }
